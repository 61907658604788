* {
  box-sizing: border-box;
}

.App {
  color: white;
  text-align: center;
  background-color: black;
  max-width: 100vw;
  margin: 0 auto;
  font-family: Cambria, Georgia, serif;
}

/* nav style */

nav {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 1rem;
  border-bottom: 3px solid hsl(0, 96%, 38%);
}

.Header {
  position: sticky;
  top: 0;
  z-index: 1000; 
  width: 100%; 
  background-color: black;;
}

ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

.active-link {
  color: white;
  border-top: 4px solid white;
}

nav a {
  color: rgb(146, 143, 143);
  font-size: 1.5rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav a:hover, .active-link {
  border-top: 4px solid white;
  color: white
}

.cursor {
  opacity: 1;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.logo {
  height: 12rem;
  width: 16rem;
}

.award {
  height: 8rem;
}

.banner {
  border: 2px solid white;

  display: block; 
  width: 80%; 
  margin: 1rem auto;
  overflow: hidden; 
  box-sizing: border-box; 
}

.typing {
  background: url("./images/banner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0rem;
  border: 2px solid white;
  text-shadow:  -1px -1px 0 #000,  
  1px -1px 0 #000,
 -1px  1px 0 #000,
  1px  1px 0 #000;
  font-size: .9rem;
  height: 9rem;

  margin: 1rem 1rem .5rem 1rem;
}


/* home */

.home {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: hsl(225, 83%, 56%);
  border: 2px solid white;
  margin: .5rem auto;
  padding: 1rem;
}

.section-2 {
  background-color: hsl(0, 96%, 38%);
  padding: 1rem;
  margin: .5rem auto;
  border: 2px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.leftbox {
  width: 60%;
}

.section-container {
  display: flex;
  margin: 0 auto;
  width: 80%;
  gap: 20px;
}

.section-1 {
  flex: 1 1 33%;
  box-sizing: border-box;
}

.section-2 {
  flex: 1 1 66%;
  box-sizing: border-box;
}

.section-3 {
  display: flex;
  justify-content: space-evenly; 
  align-items: center;
  padding: 0rem;
  flex-wrap: wrap-reverse;
  width: 83%;
  margin: 0rem auto;
  margin-bottom: 1rem;
}

.flip-card {
  background-color: transparent;
  width: 47%;
  height: 20rem;
  perspective: 1000px; /* Gives depth to the flip effect */
  margin: .5rem;
  box-sizing: border-box;
}

.flip-card-container {
  width: 100%;
  height: 100%;
  border: 2px solid white; 
  position: relative;
}

.flip-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
  transform-origin: center;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: .5rem;
}

.flip-card-front {
  color: white;
  
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
 
}

.flip-card h3 {
  margin: .8rem;
}


.blue-background {
  background-color: hsl(225, 83%, 56%);
  border: 2px solid white;
  padding: 1rem;
}

.red-background {
  background-color: hsl(0, 96%, 38%);
  border: 2px solid white;
  padding: 1rem;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  color: hsl(0, 96%, 38%);
  text-align: center;
  border-radius: 5px;
  text-decoration: none; 
  cursor: pointer;
  font-weight: bold;
}

.button-2 {
  color: hsl(225, 83%, 56%)
}

.button:hover {
  background-color: lightgrey;
}

.section-4 img {
  height: 7rem;
}

.section-4 {
  width: 80%;
  margin: 0 auto;
}


/* footer */

.footer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.footer-flexbox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.links {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 80%;
  margin: 0 auto;
  margin-top: -2rem;
}

.links a img {
  transition: transform 0.3s ease, opacity 0.3s ease;
  display: block;
  width: 100%;
}

.links img {
  height: 8rem;
  width: auto;
}

.links a:hover img {
  transform: scale(1.05);
  opacity: 0.8;
}

.share-icons a {
  transition: color 0.3s ease;
  color: #ccc; 
  margin-bottom: .5rem;
}

.share-icons a:hover {
  color: #ffffff; 
}

.share {
  display: flex;
  flex-direction: column;
  border-top: 2px solid white;
  padding-top: .5rem;
  margin: 0rem 0;
}

.share-icons {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 2px solid white;
  padding: .5rem;
  margin-bottom: 1rem;
}

.info {
  text-align: start;
}


/* location */

.location {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

.location img {
 height: 2.2rem;
 width: 1.5rem;
}

.map {
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
}

.location-container {
  display: flex;
  width: 70%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
  
}




.apt {
  background-color: rgb(221, 192, 192);
  padding: 1rem;
  color: rgb(139, 71, 71);
  text-shadow: 1px 1px white;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgb(139, 71, 71);

}


.apt h6 {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.apt p {
  margin: 0;
}

.address p {
  margin: 0;
}

.address img {
  margin-bottom: -1rem;
}



/* service */
.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; 
  width: 80%;
  max-width: 100vw;
  padding: 1rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.service-item {
  display: flex;
  align-items: flex-start;  
  gap: 10px;  
  position: relative;
  z-index: 1;
}
/*
.services::after {
  content: '';
  position: absolute;
  left: 3rem;
  top: 4rem;
  bottom: 6rem;
  width: 2px;
  background-color: transparent;
  border-left: 2px dotted white;
  z-index: 0;
}
*/
.icon-circle {
  background-color: #ccc; 
  border-radius:  50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4rem;
  min-height: 4rem; 
  position: relative;
  z-index: 1;
}

.services-line {
  position: absolute;
 left: 3rem;
  border-left: 2px dotted white;
  z-index: 0;
}

.service-item:nth-child(odd) .icon-circle {
  background-color: hsl(225, 83%, 56%);
}

.service-item:nth-child(even) .icon-circle {
  background-color: hsl(0, 96%, 38%);
}

.service-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1rem;
  text-align: left;
}

.service-text p {
  text-align: left;
  margin-top: .5rem;
}

.service-text h4 {
  margin: 0;
  font-size: 1.4rem;
}


/* hours */
.hours {
  display: flex;
  align-items: flex-start; 
  justify-content: space-evenly;  
  width: 80%;  
  margin: 0 auto;
  flex-wrap: wrap;
  padding: .5em;
  margin-top: -2rem;
}

.apt-2 {
  padding: 1rem ;
  background-color: rgb(221, 192, 192);
  color: rgb(139, 71, 71);
  text-shadow: 1px 1px white;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  border: 2px solid rgb(139, 71, 71);
  margin: 2rem;
  
}

.hours-schedule {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  padding: 1rem;
}

.hour-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: .5rem 0;
}

.hour-item p {
  font-size: 1.3rem;
  margin: 0;
 text-align: left;

}

.apt-2 p {
  font-size: 1rem;
  margin: 0;
}

.apt-2 h6 {
  margin: 0.5rem 0;
  font-size: 1.5rem;
}

.red-circle {
  background-color: hsl(0, 96%, 38%); 
}

.blue-circle {
  background-color: hsl(225, 83%, 56%); 
}

.white-circle {
  background-color: white;
  color: hsl(225, 83%, 56%);
}

/* For mobile devices */
@media (max-width: 786px) {
 
nav {
  width: 100%;
}

ul {
  justify-content: space-evenly;
}

nav a {
  font-size: .9rem;
}


.banner {
  width: 100%; 
}

.services {
  width: 90%;
}

.leftbox {
  width: 100%;
}

.apt-2 {
  margin: 0;
  margin-bottom: 1rem;
}

.location {
  width: 100%;
}


.location-container {
  width: 100%;
}

.apt {
  margin-top: 1rem;
}

.typing {
  width: 100%;
  margin: 1rem auto;
}

.links img {
  height: 0rem;
}

.links {
  width: 100%;
  margin-top: -4rem;
}

.flip-card {
  width: 80%;
}



.footer-container {
  padding: 1rem;
}

.hide {
  display: none;
}

.footer-flexbox {
  flex-direction: column;
}

.ccs {
  padding-bottom: .5rem;
}

.info {
  text-align: center;
}



.section-2 {
  justify-content: center;
}
  
.section-1 {
  margin-bottom: -.6rem;
}
.section-3 {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.section-container {
  flex-wrap: wrap;
}


.leftbox p {
  text-align: center;
}

.hour-item p {
  font-size: 1.1rem;
}


.flip-card h3 {
  margin: .6rem;
  margin-bottom: -.5rem;
  width: 100%;
}


}


